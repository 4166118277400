/*
 * @Description  : v6方式渲染子组件，在组件中使用 <Outlet /> 渲染子组件
 * @Autor        : yanwang
 * @CreateDate   : 2023-05-17 17:20:32
 * @LastEditors  : yanwang
 * @LastEditTime : 2023-05-18 10:42:57
 */

import { createElement } from 'react';
import { Route, Navigate, RouteProps, Routes, Outlet } from 'react-router-dom';
// import KeepAlive from 'react-activation';

/**
 * 1. key 和 path 至少指定一个
 * 2. 指定 index 后不需要指定path
 * 3. 指定 index 后不可以有children
 */
export type IRoute = {
  key?: any;
  path?: string;
  meta?: any;
  redirect?: string;
  children?: IRoute[];
  index?: boolean; // 是否为默认子路由, 只有最后一级组件才可以添加index, 不在需要path
  component?: any;
  [k: string]: any;
};

const Render = (route: any) => {
  const { component: Component, wrappers } = route;

  const newProps = {
    route, // 给组件添加自定义属性
  };
  if (Component) {
    let ret = (
      // <KeepAlive when={!!route.keepAlive}>
      // 渲染本组件
      <Component {...newProps} />
      // </KeepAlive>
    );

    // 循环嵌套包装父组件;
    if (wrappers) {
      let len = wrappers.length - 1;
      while (len >= 0) {
        ret = createElement(wrappers[len], newProps, ret);
        len -= 1;
      }
    }
    return ret;
  }

  return null; // 不存在redirect、children、component
};

const renderRoutes: any = (routes: IRoute[]) =>
  routes.map((route, ind) => {
    const {
      key,
      path,
      redirect,
      index = false,
      component: Component,
      children,
    } = route;

    return redirect ? ( // 如果有重定向
      <Route
        key={route.key || route.path || ind}
        path={route.path}
        element={<Navigate to={redirect} />}
      />
    ) : !Component && children ? ( // 如果本组件不存在component，直接渲染其子组件
      <Route
        index={index}
        key={key || path || ind}
        path={path}
        element={<Outlet />}
      >
        {renderRoutes(children)}
      </Route>
    ) : (
      // v6方式渲染子组件， 在子组件中使用<Outlet />渲染
      <Route
        index={index}
        key={key || path || ind}
        path={path}
        element={<Render {...route} />}
      >
        {children ? renderRoutes(children) : null}
      </Route>
    );
  });

export default renderRoutes;
